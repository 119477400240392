.Mui-disabled {
  opacity: 0.6;
  text-shadow: none;
}

.MuiRadio-root.Mui-disabled {
  color: #8c8c8c;
}

.MuiRadio-colorPrimary {
  color: #00248c;
}

.MuiTableCell-head {
  text-transform: none;
}

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #1d8102;
  --toastify-color-warning: #ec7211;
  --toastify-color-error: #d13212;
  --toastify-toast-min-height: 50px;
}

.Toastify__toast-container {
  width: 40%;
  top: 5px;
  height: 60px;
}

@media (min-width: 1000px) {
  .Toastify__toast-container {
    width: 35%;
  }
}

.Toastify__close-button {
  position: absolute;
  top: 50%;
  left: 97%;
  transform: translate(-50%, -50%);
}

.Toastify__toast-icon>.anticon {
  width: 100%;
  height: 100%;
}

.Toastify__toast-icon>.anticon>svg {
  width: 100%;
  height: 100%;
}

.Toastify__toast {
  margin-bottom: 2px;
}

.css-hq44io {
  background-color: rgb(255, 255, 255);
  color: rgb(38, 38, 38);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  overflow: hidden;
  position: relative;
  border: 1px solid rgb(230, 235, 241);
  border-radius: 4px;
  box-shadow: inherit;
}

.css-nbt2v6 {
  display: flex;
  align-items: center;
  padding: 20px;
}

.css-11qjisw {
  flex: 1 1 auto;
}

.css-1hz68vq {
  margin: 0px;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.57;
  font-family: Inter, sans-serif;
  display: block;
}

.css-d7wf4l {
  margin: 0px;
  flex-shrink: 0;
  border-width: 0px 0px thin;
  border-style: solid;
  border-color: rgb(240, 240, 240);
}

.css-gazh6o:last-child {
  padding-bottom: 20px;
}

.css-11hlwqc {
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  margin-top: -8px;
  width: calc(100% + 8px);
  margin-left: -8px;
  align-items: center;
}

.css-1d3bbye {
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  width: 100%;
}

.css-1wxaqej {
  box-sizing: border-box;
  margin: 0px;
  flex-direction: row;
}

.css-1uewctb {
  flex-basis: 0px;
  flex-grow: 1;
  max-width: 100%;
}

.css-or4ocw {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: rgb(255, 191, 0);
}

.css-xrbvut {
  margin: 0px;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.57;
  font-family: Inter, sans-serif;
  text-align: left;
  color: rgb(140, 140, 140);
}

.css-dvso3r {
  box-sizing: border-box;
  margin: 0px;
  flex-direction: row;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
}

.simplebar-placeholder{
  display: none;
}

/* .disabled-menu-item:hover{
  cursor: not-allowed;
  background-color: rgba(255, 255, 255,0.5);
  opacity: 0.8;
} */

.css-2wed5z-MuiButtonBase-root-MuiCheckbox-root.size-small .icon{
  height: 15px !important;
}

.disabled-menu-item{
  opacity: 0.5;
  cursor: not-allowed;
}
.switcher-menu-item:hover{
  background-color: #2824a3 !important;
}

.css-1oklli8-MuiButtonBase-root-MuiRadio-root.size-small .icon .dot {
  width: 13px;
  height: 13px;
  top: 0.8px;
  left: 0.7px;
}

.MuiTabPanel-root {
  padding: 0px !important;
}

.css-1gercii-MuiPaper-root-MuiCard-root {
  margin-left: 0 !important;
}

.btncopy>span {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.MuiTooltip-tooltip {
  background-color: #2824a3;
}

.hidefilter:has(input) {
  display: none;
}

.text-decoration-none {
  text-decoration: none;
}

.d-none {
  display: none;
}

@media screen and (min-width: 768px) {
  .mt-md-0 {
    margin-top: 0 !important;
  }
}

/* Works on Firefox */
/* * {
  scrollbar-width: thin;
  scrollbar-color: #ffffff #00248c;
} */

@media screen and (min-width: 768px) {
  .d-md-flex {
    display: flex;
  }
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #8c8c8c;
  border-radius: 5px;
  border: 2px solid #ffffff;
}

.apexcharts-legend-series .apexcharts-legend-marker {
  left: 0 !important;
}

/* .apexcharts-svg {
  overflow: visible;
} */

/* Hide the extra eye icon in the Edge browser */
input[type='password']::-ms-reveal,
input[type='password']::-ms-clear {
  display: none;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}